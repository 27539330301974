<template>
    <div>        
        <!-- Table Container Card -->
        <b-card no-body class="mb-50">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button size="sm" variant="primary" @click="isActiveModal = true">
                            <span class="text-nowrap">
                                Agregar
                            </span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input size="sm" v-model="searchQuery" class="d-inline-block _mr-1" placeholder="Buscar por nombre"/>
                                <b-input-group-prepend>
                                <b-button size="sm" variant="primary">
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                                </b-input-group-prepend>                                
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- User List -->
            <!-- <draggable :list="columns" :element="'tbody'" @end="onDragEnd"> -->
            <b-table
                ref="refUsersListTable"
                :items="listUsers"
                responsive
                :fields="columns"
                primary-key="id"
                show-empty
                empty-text="No se encontraron resultados"
                :busy.sync="loading"
                small
            >
                <!-- Empty -->
                <template slot="empty">
                    <div v-if="loading" class="d-flex justify-content-center mb-1">
                        <b-spinner
                            style="width: 3rem; height: 3rem"
                            class="mr-1"
                            label="Loading..."
                            variant="primary"
                        />
                    </div>
                </template>

                <!-- Actions -->
                <template #cell(actions)="data">
                    <div class="demo-inline-spacing">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning" class="btn-icon" size="sm"
                            @click="editUser(data.item)"
                            v-b-tooltip.hover.right="`Editar`">
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                            v-if="data.item.role_Id !== 1"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon" size="sm"
                            @click="deleteUser(data.item)" v-b-tooltip.hover.right="`Eliminar`">
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </div>
                </template>
            </b-table>
            <!-- </draggable> -->
        </b-card>

        <!-- Modal Roles -->
        <b-modal ref="infoModalRef" id="modalCreateUpdateProducts" :title="titleModal" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal" size="md">
            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row>

                        <b-col cols="12">
                            <b-form-group label="Nombre Completo" label-for="fullName">
                                <validation-provider #default="{ errors }" name="nombre completo" rules="required">
                                <b-form-input id="fullName" v-model="user.fullName" :state="errors.length > 0 ? false : null"
                                    name="fullName" placeholder="Nombre Completo" />
                                    <small v-if="errors[0]" class="text-danger">El nombre completo es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Email -->
                        <b-col cols="12" md="12">                            
                            <b-form-group label="Correo" label-for="email">
                                <b-form-input :disabled="isDetail" id="email" v-model="user.email" trim
                                    placeholder="Insertar Correo" />
                            </b-form-group>
                        </b-col>

                        <!-- Phone -->
                        <b-col cols="12" md="12">                            
                            <b-form-group label="Teléfono" label-for="phone">
                                <b-form-input :disabled="isDetail" id="phone" v-model="user.phoneNumber" trim
                                    placeholder="Insertar Teléfono" />
                            </b-form-group>
                        </b-col>

                        <!-- UserName -->
                        <b-col cols="12" md="12">                            
                            <b-form-group label="Usuario" label-for="userName">
                                <validation-provider #default="{ errors }" name="usuario" rules="required">
                                    <b-form-input :disabled="isDetail" id="userName" v-model="user.userName" trim
                                        placeholder="Insertar Usuario" :state="errors.length > 0 ? false : null"/>
                                        <small v-if="errors[0]" class="text-danger">El usuario es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Password -->
                        <b-col cols="12" md="12">                            
                            <b-form-group label="Contraseña" label-for="password" :description="isEdit ? `La contraseña solo se guarda si se escribe algo` : ``">
                                <validation-provider #default="{ errors }" name="contraseña" :rules="isEdit ? '' : 'required'">
                                    <b-input-group class="input-group-merge">
                                        <b-form-input id="password" v-model="user.password" :type="passwordFieldType"
                                                placeholder="Insertar Contraseña" :state="errors.length > 0 ? false : null"/>
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePassword"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>

                                    <small v-if="errors[0]" class="text-danger">La contraseña es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Role -->
                        <b-col cols="12">
                            <validation-provider #default="{ errors }" name="rol de usuario" rules="required">
                                <b-form-group label="Rol de Usuario" label-for="user-role" :class="errors.length > 0 ? 'is-invalid':''">                                
                                    <v-select v-model="user.roleName" :options="roles"
                                        :reduce="(val) => val.name"
                                        :clearable="false"
                                        :searchable="false"
                                        input-id="user-role"
                                        label="name"
                                        placeholder="Selecciona una opción"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        El rol de usuario es requerido
                                    </b-form-invalid-feedback>                       
                                </b-form-group>                                
                            </validation-provider>
                        </b-col>
                    </b-row>


                    <!-- Form Actions -->
                    <hr v-if="!isDetail">
                    <div v-if="!isDetail" class="text-right mt-2">
                        <b-button :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                            variant="outline-secondary" @click="closeModal">
                            Cancelar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>
</template>

<script>
import _ from "lodash";
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "animate.css";
import draggable from 'vuedraggable'

import UsersProvider from "@/providers/Users";
const UsersResource = new UsersProvider();

import RolesProvider from "@/providers/Roles";
import store from '@/store';
const RolesResource = new RolesProvider();

if (process.env.MIX_FALLBACK_LOCALE === "es") {
    localize('es');
}

export default {
    name: 'UsersList',
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        BTooltip, 
        VBTooltip,
        VBToggle,
        vSelect,
        BFormCheckbox,
        BSidebar,
        draggable,

        //
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            password,
            hideSidebar: false,
            users: [],
            user: {},
            perPageOptions: [10, 25, 50, 100],
            perPage: 10,
            currentPage: 1,
            totalUsers: 0,
            columns: [               
                {
                    key: "id",
                    label: 'ID',
                },
                {
                    key: "userName",
                    label: 'Usuario',
                }, 
                {
                    key: "fullName",
                    label: 'Nombre',
                },
                {
                    key: 'roleName',
                    label: 'Rol',
                },
                {
                    key: "email",
                    label: 'Correo',
                },
                {
                    key: "phoneNumber",
                    label: 'Teléfono',
                },
                { key: "actions", label: 'Acciones' },
            ],
            sortBy: "id",
            sortDesc: true,
            searchQuery: null,
            from: 0,
            to: 0,
            loading: false,
            isEdit: false,
            isActiveModal: false,
            isDetail: false,
            titleModal: "Alta de Usuario",
            roles: [],
            passwordFieldType: "password",
        };
    },
    computed: {
        passwordToggleIcon() { 
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
        },
       listUsers () {            
            let result = this.searchQuery ? this.users.filter(o =>
                    o.fullName?.toLowerCase().includes(this.searchQuery.toLowerCase())                    
                ) : this.users;
            return result
       }
    },
    watch: {
       
    },
    async mounted() {
        await this.getRoles()
        await this.getUsers()
    },
    methods: {
        onDragEnd(newFields) {
            this.columns = newFields;
        },
        getRules() {
             // Si es un nuevo usuario, la validación es requerida
            if (!this.isEdit) {
                return 'required';
            } else {
                // Si es una actualización, la validación no es requerida
                return '';
            }
        },
        togglePassword() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        async getRoles() {
            this.loading = true
            const { data } = await RolesResource.index()
            this.loading = false
            this.roles = data
        },
        closeModal() {
            this.isActiveModal = false
            this.isEdit = false
            this.user = {}
            this.titleModal = 'Alta de Usuario'
        },
        validatePassword() {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{6,}$/;
            if (!passwordRegex.test(this.user.password)) {
                this.danger('La contraseña debe tener al menos 6 caracteres y contener al menos una letra mayúscula, una letra minúscula, un dígito y un carácter especial.');
                return false
            }
            return true
        },
        async onSubmit () {

            const payload = {                
                id: this.user.id,
                userName: this.user.userName,
                fullName: this.user.fullName,
                email: this.user.email,
                phoneNumber: this.user.phoneNumber,
                password: this.user.password || '',
                roleName: this.user.roleName,
                editPassword: this.user.password ? true : false,
                idEmpresa: store.getters['auth/getConexionId']
            }
            console.log(payload)

            if (this.user.password) {
                if (!this.validatePassword()) {
                    return false
                }
            }

            if (!payload.phoneNumber || payload.phoneNumber == "") {
                delete payload.phoneNumber
            }

            if (!payload.password || payload.password == "") {
                delete payload.password
            }

            try {

                this.loading = true                
                const { data } = this.isEdit ? await UsersResource.update(this.user.id, payload) : await UsersResource.store(payload);
                console.log(data)
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message);
                    await this.getUsers()
                    this.isActiveModal = false
                    this.user = {}
                    this.isEdit = false

                } else {
					this.danger(data.message);
                }
            } catch (e) {
                console.log(e)
                this.loading = false
                this.handleResponseErrors(e)
            }
        },       
        editUser(item) {
            this.isEdit = true
            this.user = {...item}
            this.isActiveModal = true
            this.titleModal = 'Editar Usuario'
        },
        async getUsers() {
            this.loading = true;
            const { data } = await UsersResource.index();
            this.loading = false;
            this.users = data.map(u => ({...u.user, roleName: u.roles[0]}))
        },
        deleteUser(item) {
            this.$swal({
                title: `¿Desea eliminar el registro?`,
                text: `¡Esto no se puede revertir!`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelar`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await UsersResource.destroy(item.id)
                        this.loading = false

                        if (data.isSuccesful) {
                            this.success(data.message)
                            this.users = this.users.filter(user => user.id !== item.id)
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    }catch(e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
