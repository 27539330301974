import axios from '@/libs/axios'

export default class Roles {
    
    index() {
        return axios.get(`/Cuentas/ObtenerRoles`);    
    }
    
    store(payload) {
        return axios.post(`/Cuentas/RegistrarRol`, payload);    
    }

    update(id, payload) {
        return axios.put(`/Cuentas/ActualizarRol/${id}`, payload);    
    }

    destroy (id) {
        return axios.delete(`/Cuentas/EliminaRol/${id}`);
    }
}
